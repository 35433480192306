import React from 'react'

//images
import userGirl from './images/userGirl.png'

import mobile1 from './images/mobile1.jpeg'
import mobile2 from './images/mobile2.jpeg'
import mobile3 from './images/mobile3.jpeg'
import mobile4 from './images/mobile4.jpeg'

import chat from './images/chat.png'
import playStore from './images/play-store.png'

//fontAwesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebookF, faTwitter, faInstagram} from '@fortawesome/free-brands-svg-icons'

class Landing extends React.Component {

    
    state= {menu: false}


 toggleMenu= (e) =>{
    e.preventDefault()
    let collapse= document.getElementsByClassName('collapse')

     if(collapse[0].className==='collapse navbar-collapse '){

          collapse[0].className = 'collapse navbar-collapse show'
         
     }else{
      collapse[0].className = 'collapse navbar-collapse '
      
     }
}

    
    render(){

        const show = (this.state.menu) ? "show" : "" ;

        return (
            <div >

                {/**navbar */}

                <nav className='navbar navbar-light navbar-expand-lg container mt-3'
                 style={{ position: 'absolute', right:'0', left: '0'}}>
                    <a className='navbar-brand' id='navbar-brand' style={{fontWeight:'bold', color:'rgb(245 221 0)', fontSize:'x-large'}}>
                        TOKO CHAT
                    </a>

                    <button className="navbar-toggler" type="button" onClick={ this.toggleMenu }>
      <span className="navbar-toggler-icon"></span>
    </button>

    <div className={"collapse navbar-collapse " + show} id="navbar-collapse">
        {/**
        <a className='nav-link ' href='/home' >الرئيسية</a>
         * 
         */}
        <a className='nav-link' href='/names_package'> الاسماء المسجلة</a>
        <a className='nav-link ' href='/root' >الروت</a>
        <a className='nav-link ' href='/contact-us' >اتصل بنا</a>
        <a className='nav-link ' href='/privacy' > سياسة الخصوصية</a>
         
        <a className='nav-link' >لوحة المستخدم</a>


    </div>

    
                </nav>

                {/**first section */}
                <div className='row m-0' style={{ minHeight: '650px', height: 'fit-content', flexFlow: 'wrap-reverse'}}>

                    <div className='col-12 col-lg-6 justify-content-center pt-5 '
                    style={{background: '#fbeb580d'}}
                    >

                        <div style={{ width: '800px', maxWidth: '85%' }}
                            className='mx-auto mt-5 pt-5'>

                            <h1 style={{fontWeight: 'bold'}}>   اكبر تجمع عربي لدينا للدردشة الصوتية </h1>
                            <h1 style={{fontWeight: 'bold'}}>   ملتقى توكو شات العربي</h1>


                            <p className=' my-5'
                            style={{lineHeight: '20px', fontSize: '20px'}}
                            >
                                انضم معنا الان لا تحتاج الا تسجيل الدخول الي الدردشة
                               </p>

                               <p className=' my-5'
                            style={{lineHeight: '20px', fontSize: '20px'}}
                            >
   ملتقى توكو شات الصوتي .. حمل الان                           </p>


   <a id ='app-link' className='btn' href = 'https://play.google.com/store/apps/details?id=com.tokochatapp&hl=ar' 
                           style={{backgroundImage: `url(${playStore})`, width: 220}}></a>
                      
                            {/** <button className='btn btn-success btn-lg px-5 mb-5'
                             onClick={()=>{
                                 window.location.href= '/home'
                             }}> ابدأ معنا</button> */}
                        </div>
                    </div>


                    {/**----------------------------------------------------------------------------------------------------- */}

                    
                    <div className='col-12 col-lg-6 p-0'
                        style={{backgroundImage: `url(${userGirl})`, backgroundPosition: 'top', backgroundSize: '105% 105%' ,backgroundRepeat: 'no-repeat' , minHeight: '650px' }}>
                       
                    </div>

                </div>


                {/**third section */}
              

                    <div 
                            className='mx-auto mt-5 pt-5 text-center'>

                            <h1 style={{fontWeight: 'bold'}}>
                                " مميزات تطبيقنا "
                                </h1>

                            <p className=' my-5'
                            style={{lineHeight: '15px', fontSize: '20px'}}
                            >
                               غرف ذهبية المستوي الاول في ساحة الغرف لدينا سعة اكبر و الوان مختلفة في قائمة الغرف
                                
                                </p>

                                <p className=' my-5'
                            style={{lineHeight: '15px', fontSize: '20px'}}
                            >
                               غرف مميزة المستوي الثاني في ساحة الغرف لدينا سعة اقل و الوان مختلفة في قائمة الغرف
                                
                                </p>

                                <p className=' my-5'
                            style={{lineHeight: '15px', fontSize: '20px'}}
                            >
                               غرف فضية المستوي الثالث في ساحة الغرف لدينا سعة اقل و الوان مختلفة في قائمة الغرف
                                
                                </p>

                                <p className=' my-5'
                            style={{lineHeight: '15px', fontSize: '20px'}}
                            >
خاصية الصور الخارجية للتعريف عن الغرفة تتضمن حالة تعريف موجودة لدي صاحب الغرفة                                 
                                </p> <p className=' my-5'
                            style={{lineHeight: '15px', fontSize: '20px'}}
                            >
مساحة اعلان في قائمة الغرف لمشاهدة العروض الاسبوعية و الشهرية                                
                                </p>

                                <p className=' my-5'
                            style={{lineHeight: '15px', fontSize: '20px'}}
                            >
خاصية التكلم مع موظفي توكو شات مباشر من خلال ايقونة الدعم في قائمة الغرف                                
                                </p>

                                
      
                        </div>

                  
                        <div className='row text-center py-5'>
                            <div className='col-6 col-md-3'>
                        <img src={mobile1} className='shadow'  style={{ maxWidth:'80%', maxHeight: '650px', margin:'20px', }}/>

                            </div>
                            <div className='col-6 col-md-3'>
                        <img src={mobile2} className='shadow' style={{ maxWidth:'80%', maxHeight: '650px', margin:'20px'}}/>

                            </div>
                            <div className='col-6 col-md-3'>
                        <img src={mobile3} className='shadow'  style={{ maxWidth:'80%', maxHeight: '650px', margin:'20px'}}/>

                            </div>
                            <div className='col-6 col-md-3'>
                        <img src={mobile4} className='shadow'  style={{ maxWidth:'80%', maxHeight: '650px', margin:'20px'}}/>

                            </div>
                        </div>


                {/**footer  --------------------------------------------------------------------------------------------------------*/}

                <div className='p-5 footer row m-0' >

                    {/**first section */}
                    <div className=' text-center' style={{width: 'fit-content'}}>
                        <h3 style={{fontWeight:'bold', color:'#fbeb58'}}>TOKO-CHAT</h3>

                        <div className='d-flex my-4'>
                            <button className='btn btn-outline-light mx-2'>
                                <FontAwesomeIcon icon={faFacebookF}/>
                            </button>

                            <button className='btn btn-outline-light mx-2'>
                                <FontAwesomeIcon icon={faTwitter}/>
                            </button>

                            <button className='btn btn-outline-light mx-2'>
                                <FontAwesomeIcon icon={faInstagram}/>
                            </button>
                        </div>
                    </div>

                    {/**second section */}
                   

                      {/**third section */}
                     
                </div>
            </div>
        )
    }
}


export default Landing
