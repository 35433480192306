

export const CLEAR_ERRORS = "CLEAR_ERRORS"
export const GET_ERRORS = "GET_ERRORS"

export const GET_PACKAGES = "GET_PACKAGES"







