import {
 
    GET_PACKAGES,

} from '../actions/types'


const initialState = {
   packages: [],
   package: {}
}

export default (state = initialState, action) => {
       switch (action.type) {
        
           case GET_PACKAGES:
               return{
                   ...state,
                   packages: action.payload
               }
          

           default: 
           return{
               ...state
           }
        }

    }