import React from 'react'

import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'

//pages
import Landing from './components/landing'

import Payment from './components/payment';
import Names from './components/names_packages'
import Root from './components/root'
//redux
import {Provider} from 'react-redux'
import store from './store'

import Contact from './components/contact'
import Privacy from './components/privacy';

class App extends React.Component{


   componentDidUpdate(){
    window.process = 'process/browser.js'
   }
   
  render(){
    


  return (
    <Provider store = {store}>
      <div className="App">

<BrowserRouter>
<Routes>
  <Route exact path='/' element={<Landing/>} />
 
  <Route path='/payment' element={<Payment/>}/>
  <Route path='/privacy' element={<Privacy/>}/>

  <Route path='/names_package' element={<Names/>}/>
  <Route path='/root' element={<Root/>}/>
  <Route path='/contact-us' element={<Contact/>}/>


</Routes>
</BrowserRouter>


</div>
    </Provider>
  );
  }
}

export default App;
