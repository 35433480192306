import React from 'react'



class Navbar extends React.Component{
     
    state= {menu: false}


 toggleMenu= (e) =>{
    e.preventDefault()
    let collapse= document.getElementsByClassName('collapse')

     if(collapse[0].className==='collapse navbar-collapse '){

          collapse[0].className = 'collapse navbar-collapse show'
         
     }else{
      collapse[0].className = 'collapse navbar-collapse '
      
     }
}

    
    render(){

        const show = (this.state.menu) ? "show" : "" ;

        return(
            <div>
                
                <nav className='navbar navbar-light navbar-expand-lg shadow-sm  '
                 style={{direction: 'rtl', background: '#ffd700bf'}}>
                  <div className='container'>
                  <a className='navbar-brand' href= '/' style={{fontWeight:'bold', color:'white', fontSize:'x-large'}}>
                        TOKO CHAT
                    </a>

                    <button className="navbar-toggler" type="button" onClick={ this.toggleMenu }>
      <span className="navbar-toggler-icon"></span>
    </button>

    <div className={"collapse navbar-collapse " + show} id="navbarToggleExternalContent">
       {/** <a className='nav-link ' href='/home' >الرئيسية</a> */}
        <a className='nav-link' href='/names_package'> الاسماء المسجلة</a>
        <a className='nav-link ' href='/root' >الروت</a>
        <a className='nav-link ' href='/contact-us' >اتصل بنا</a>

        <a className='nav-link ' href='/privacy' >الخصوصية</a>


        <a className='nav-link' >لوحة المستخدم</a>

   
    </div>
                  </div>

    
                </nav>

            </div>
        )
    }
}

export default Navbar