import React from  'react'
import Navbar from './navbar'
import {connect} from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons'
class Payment extends React.Component{

    render(){
    let packages = this.props.packages.packages || []


        return(
            <div className='text-center'>
              <Navbar/>

              <h4 className='mt-5'>
                  باقات الغرف
              </h4>

              <div className='row mt-5' style={{justifyContent: 'center'}}>
                 {packages.map(p => (
                      <div className='col-12 col-md-3'>

                      <div className='card shadow mx-auto' style ={{width: '200px'}}>
                          <div className='card-header bg-warning text-light'>
                              <h5>{p.name}</h5>
                              <h5>{p.price} دولار</h5>
                               </div>
                                
                             <div className='card-body'>

                                 <h5>سنة</h5>

                                 <hr></hr>

                                 <h5>{p.capacity} مستخدم</h5>

<hr></hr>

<h5>  {p.admin} ادمن </h5>

<hr></hr>


<h5 >  {p.super_admin} سوبر ادمن </h5>

<hr></hr>

<h5>  {p.master} ماستر </h5>

<hr></hr>

<h5>  {p.member} ممبر </h5>

<hr></hr>

<h6>تطبيق الموبايل 
    <FontAwesomeIcon icon ={faMobileAlt} className='text-success mx-3'/>
</h6>


                             </div>
                      </div>
                  </div>
                 ))}





                   </div>


            </div>
        )
    }
}


const mapState = (state) => ({
    packages: state.packageState
})


export default connect(mapState) (Payment)