import React from 'react'
import Nav from './navbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faStar} from '@fortawesome/free-solid-svg-icons'

class Names extends React.Component{
    render(){

        
    let names_pack = [{duration: 'سنة', price: '80'}, {duration: '6 اشهر', price: '50'}, 
    {duration: '3 اشهر', price: '35'}, {duration: 'شهر', price: '20'}]

    let royal_pack = [{duration: 'سنة', price: '220'}, {duration: '6 اشهر', price: '135'}, 
    {duration: '3 اشهر', price: '75'}, {duration: 'شهر', price: '40'}]

        return(
           <div>
<Nav/>

                <div className='text-center container'>



   
<h4 className='mt-5 text-info'>
  باقات الاسماء     المحمي        </h4>


<h6 className='my-5 mx-auto ' style={{maxWidth: '1000px'}}>
حجز وحماية الاسماء
يمكن لاي مستخدم حجز اسمه الخاص وتسجيله في البرنامج بالتالي حماية الاسم من دخول اي مستخدم آخر باسم مشابه لاسمه والاسم يكون محمي في كامل البرنامج وفي كل مواقع الدردشة

يوجد ملف شخصي يوضع فيه معلومات صفحته الشخصية مثل الاسم والعمر والجنسية وتاريخ الميلاد والمهنة .. الخ

يمكن الدخول بالاسم المسجل باسم مستخدم كزائر او كمشرف بالغرفة او باسم روت

ويتميز الاسم المسجل بوجود العلامة المميزة بجانب الاسم في قائمة الاسماء وعلى الشات الكتابي مع كل رسالة مرسله

كل اسم مسجل يكون مدته حسب الحجز من تاريخ الاشتراك وينتهي تلقائيا عند انتهاء المدة بالتالي يصبح الاسم متاح لأي مستخدم اخر
</h6>


  <div className='row mt-5' style={{justifyContent: 'center'}}>
 {names_pack.map(p => (
      <div className='col-12 col-md-3'>

      <div className='card shadow mx-auto' style ={{width: '200px'}}>
          <div className='card-header bg-info text-light'>
              <h5>الاسم المحمي</h5>
              <hr></hr>
              <h5>{p.price} دولار</h5>
               </div>
                
             <div className='card-body'>

                 <h5>{p.duration}</h5>

                 <hr></hr>

                 <h5>{"ملف شخصي"}</h5>

<hr></hr>

<FontAwesomeIcon icon={faStar} className='text-info'/>
<FontAwesomeIcon icon={faStar} className='text-info'/>

<FontAwesomeIcon icon={faStar} className='text-info'/>

<FontAwesomeIcon icon={faStar} className='text-info'/>

<FontAwesomeIcon icon={faStar} className='text-info'/>


             </div>
      </div>
  </div>
 ))}





   </div>


 
   <h4 className='mt-5 text-info'>
  باقات الاسماء  الملكي       </h4>


  <div className='row mt-5' style={{justifyContent: 'center'}}>
 {royal_pack.map(p => (
      <div className='col-12 col-md-3'>

      <div className='card shadow mx-auto' style ={{width: '200px'}}>
          <div className='card-header bg-info text-light'>
              <h5>الاسم الملكي</h5>
              <hr></hr>
              <h5>{p.price} دولار</h5>
               </div>
                
             <div className='card-body'>

                 <h5>{p.duration}</h5>

                 <hr></hr>

                 <h5>{"ملف شخصي"}</h5>

<hr></hr>

<FontAwesomeIcon icon={faStar} className='text-info'/>
<FontAwesomeIcon icon={faStar} className='text-info'/>

<FontAwesomeIcon icon={faStar} className='text-info'/>

<FontAwesomeIcon icon={faStar} className='text-info'/>

<FontAwesomeIcon icon={faStar} className='text-info'/>


             </div>
      </div>
  </div>
 ))}





   </div>



</div>
           </div>
        )
    }
}

export default Names