import React from 'react'
import Navbar from './navbar'


class Privacy extends React.Component{
    render(){

        return(
            <div>

 <Navbar/>

<h5 style ={{ padding: '50px'}}>
   <h5 style ={{fontWeight: 'bold'}}>
   شروط دخول الزوار لغرفة الدردشة في تطبيق وموقع توكو شات :
   </h5>
   {'\n'} {'\n'}
- يمنع التحدث او الكتابة بالكلام البذيء والمشاركة بكل أدب واحترام. {'\n'}
- يمنع فتح الاحاديث والحوارات التي تتطرق إلى الخلافات المذهبية والعقائدية والسياسية. {'\n'}
- يمنع التحدث بكلام يسيئ إلى الله أو الى الاديان بأي كلام غير لائق. {'\n'}
- يمنع الانضمام الى الشات بعدة اجهزة بأسماء وهميه لرفع ترتيب الغرفة وهذا سيؤدي لحظر جميع الأجهزة أو تطبيق تجاهل للاجهزة وعدم حساب تعدادها في قائمة الغرف. {'\n'}
- يمنع الانضمام الى الغرف بطريقة غير شرعية او الدخول بأجهزه وهمية أو استعمال أي برامج تؤذي الغير او استعمال تطبيقات دردشة وهمية بالتالي يؤدي لحرمانك من الانضمام بشكل دائم ودون اخبارك بذلك. {'\n'}
- يمنع الانضمام الى الشات بأسماء سياسية او او اسماء غير لائقة او غير مناسبة لمكانة التطبيق أو العاملين عليه. {'\n'}
- يمنع استخدام غرف الشات لأمور شخصية لأنها تعتبر غرف عامة ويحق للجميع استخدامها والمشاركة فيها. {'\n'}
- يمنع وضع الإعلانات داخل الغرفة ( مواقع او روابط أخرى ) او ايميل او أرقام هواتف أو صور شخصية للغير. {'\n'}
- يمنع وضع روابط لمواقع اباحية او روابط لصفحات فيها فضائح وما شابه. {'\n'}
- يمنع تشغيل الكاميرا للعرض الاباحي او التجاري ويحق لاي شخص التبليغ عن اي استخدام مخالف لتعريف الكاميرا. {'\n'}
- يمكن لاي شخص التبليغ عن الكاميرا المخالفة في الغرف ويحق لادارة التطبيق منع المستخدم المخالف من استخدام الكاميرا والكتابة الخاصة لمدة شهر او اكثر وفي حال تكرار المستخدم يطبق  عليه حظر دائم

    {'\n'} {'\n'} 
<h5 style ={{fontWeight: 'bold'}}>
شروط الاشتراك وتفعيل الميزات بغرف توكو شات أو الميزات الاخرى:
   </h5>
   {'\n'} {'\n'} 

   توكو شات: عند اشتراكك فى أحد خدمات توكو شات او الميزات الاخرى التابعة لتوكو شات.
 فإنك تعتبر موافقاً موافقة تامة على الالتزام بجميع الشروط والقوانين المسجلة فيما يلي وعدم الالتزام بها يؤدي لالغاء اشتراكك دون اي تعويض من ادارة توكو شات.
   

 {'\n'} {'\n'} 
<h5 style ={{fontWeight: 'bold'}}>
الشروط العامة لتطبيقنا:   </h5>
   {'\n'} {'\n'} 


   -الأسعار بالموقع تعتبر ثابتة ولا يمكن تخفيضها إلا في أوقات العروض الخاصة  التي تعرض في شريط الاعلانات بالتطبيق عند بدء العرض. {'\n'} 
- التكلم بشكل مباشر للمبيعات بالدرجة الأولى ، ويحق للموظف القائم بالعمل الطلب من العميل أن يقوم بارسال بريد الكتروني بدلاً من التحدث إليه مباشرة . {'\n'} 
- في حال مخالفة شرط من شروط التطبيق يحق لموظف توكو شات اجراء غرامة لإعادة الخدمة يقوم بتقديرها ويحق له تحديد السعر دون وضعه في الشروط أو قد يقوم بالغاء اشتراكك ولا يحق لك المطالبة بأي تعويض. {'\n'} 
- لا نقدم الميزات بالغرف المطلوبة أو دعمها إلا لصاحب الغرفة شخصيا وببياناته المسجلة لدينا فقط لاغير. وادارة توكو شات يمكنها تزويد بيانات العميل لاي جهة رسمية عند الطلب. {'\n'} 
- بعد دفع فاتورة اشتراكك وتفعيل الميزات بالغرفة لديك لا يمكنك استرداد المبلغ المدفوع لاي ظرف كان. {'\n'} 


{'\n'} {'\n'} 
<h5 style ={{fontWeight: 'bold'}}>
شروط اشتراكك في خدمات توكو شات:   </h5>
   {'\n'} {'\n'} 


   المخالفة لاي بند من هذه الشروط يؤدي لإزالة اشتراكك بشكل كامل بدون اي تعويض {'\n'} 
- كل الاحاديث المطروحة في الغرف من كتابة و صوت و صورة و فيديو يعتبر لدينا المسؤول عنها بالدرجة الاولى وبشكل كامل صاحب الغرفة. {'\n'} 
- عدم التطرق الى الاحاديث السياسية والدينية الطائفية في الغرف. {'\n'} 
- يمنع اعطاء كلمة مرور ال master للغرفة لاي شخص مهما كان. {'\n'} 
- فقدانك لكود ماستر الغرفة يعتبر انتهاء اشتراكك. {'\n'} 
- اعطائك اسماء ماستر للاعضاء أو اسماء روت بصلاحيات كاملة لاعضاء غير موثوق بهم ويسبب تخريب بالغرف يؤدي لإزالة اشتراكك في الغرفة أو قد يترتب عليك رسوم جانبية لاعادة تشغيل الغرفة او تصليح الاخطاء. {'\n'} 
-يمنع الانضمام بالتطبيق بعدة اجهزة بأسماء وهميه لرفع مستوى الغرف وهذا العمل سيؤدي لخفض السعة بالغرفة. {'\n'} 
- يمنع الانضمام باسماء متشابهة او رقم اي بي مشابه لاكثر من عضو واحد بالتالي يعتبر العضو الثاني وهمي وسيؤدي التعامل معه بالتجاهل وحظر جميع الاعضاء الوهمية وتخفيض سعة الغرفة لديك ثم حذف تفعيل الغرفة في حال عدم الالتزام. {'\n'} 
- يمنع وضع الإعلانات بكافة اشكالها داخل الغرف ( مثل مواقع او روابط أخرى ) {'\n'}  
- يمنع رفع صور اباحية او اعلانية او سياسية للاسم المسجل وهذا يؤدي لالغاء ميزة التسجيل لديك بشكل فوري. {'\n'} 
- يمنع منعا باتا استغلال الغرف للدردشة الخاصة للتطرق بالاعمال التجارية مهما كان نوعها والشخص الوحيد المسؤول عن عمليات المبيعات والدعم هو غرفة المبيعات والدعم توكو شات  {'\n'} 
- يمنع نسخ او كتابة روابط لصفحات فيها فضائح او انتهاك خصوصية في داخل الشات. {'\n'} 
- يمنع استخدام الملف الشخصي للاسم المحمي للاستخدام التجاري او الاعلاني  او صور وكتابات غير لائقة . {'\n'} 
- يمنع تفعيل أسم محمي او ملكي او روت لديه نفس الاحرف والشكل لأي اسم مسجل مسبقا لدينا. {'\n'} 
 واذا تم تسجيل الاسم سيتم ازالة تفعيل الاسم وتبديله وخصم مدة شهرين من تاريخ تفعيل الاسم الجديد لدينا
- لا يمكن استعادة كلمة مرور اذا كان الاسم محمي والملكي والروت  في حال كان متسجل بنفس رقم الهاتف لدينا
 أكثر من اسم , حفاظا على خصوصية العميل.
- يمنع استخدام الكاميرا للعرض الاباحي او التجاري داخل الغرف ويحق لاي زائر او عضو التبليغ عن اي مخالف لاستخدام الكاميرا سيؤدي ذلك حظر العضو فورا والغاء التسجيل لديك. {'\n'} 
- نظام الغرف لدينا يعمل كالتالي: {'\n'} 
الغرفة التي تسجل اكثر زوار لديها ترتفع لتصبح هي في المرتبة الاولى ماعدا ذلك الغرف الذهبية والمميزة. {'\n'} 
- كل الشروط السابقة يتم التعديل عليها والتطوير باستمرار وعلى الاعضاء والمسجلين مشاهدة  شروط التطبيق لدينا بشكل متكرر . {'\n'} 
    </h5>


            </div>
        )
    }
}


export default Privacy