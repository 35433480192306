import React from 'react'

import Nav from './navbar'

class Root extends React.Component{
    render(){

        let names_pack = [{duration: 'سنة', price: '100', name: 'اسم واحد'}, {duration: '6 اشهر', price: '80', name: 'اسم واحد'}, 
        {duration: '3 اشهر', price: '50', name: 'اسم واحد'}, ]

        return(
            <div>
<Nav/>

                <div className='text-center container'>



   
<h4 className='mt-5 text-warning'>
  حجز الروت        </h4>

  <h5 className='my-5'>
  بامكان مالك غرفة الدردشة اضافة اسماء الروت للغرفة بلون برتقالي ويمكن عن طريق الاسم التحكم الكامل بالغرفة مع ميزة ربط الاسم بالكمبيوتر 
  </h5>


  <div className='row mt-5' style={{justifyContent: 'center'}}>
 {names_pack.map(p => (
      <div className='col-12 col-md-3'>

      <div className='card shadow mx-auto' style ={{width: '200px' , border : 'none' ,
}}>
          <div className='card-header  text-light' 
          style ={{    background:  p.price === '100' ?'linear-gradient(45deg, #ffc107, #ffc10782)' : p.price === '80' ?
           'linear-gradient(45deg, rgb(21 203 9), rgb(21 193 10 / 18%))' : 'linear-gradient(45deg, #1dbedf, #0dcaf061)' }}>
              <h5>الروت</h5>
              <hr></hr>
              <h5>{p.price} دولار</h5>
               </div>
                
             <div className='card-body'>

                 <h5>{p.duration}</h5>

                 <hr></hr>

                 <h5>{p.name}</h5>

                 <hr></hr>

                 <h5>{"تحكم كامل"}</h5>


             </div>
      </div>
  </div>
 ))}




</div>

   </div>


 
            </div>
        )
    }
}


export default Root