import {combineReducers} from 'redux'
import errorReducer from './errorReducer'
import packagereducer from './packages'


export default combineReducers({
    error: errorReducer,
 
    packageState: packagereducer,
})
