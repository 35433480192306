import React from 'react'
import Navbar from './navbar'
import img from './images/callus.png'
import img2 from './images/chat-bubble.png'
import img3 from './images/telephone-call.png'
import img4 from './images/facebook.png'
import logo from './images/logo.png'



import FloatingWhatsapp from 'react-floating-whatsapp'


class Contact extends React.Component {
    render(){
        return(
            <div>

                <Navbar/>

                <FloatingWhatsapp phoneNumber='+4915771206561'
              accountName='toko chat'
              avatar={true}/>

                <div >
                    <div className='text-center'>

                        <h1 className='mt-5' style={{fontSize: '50px' , color : '#27b8fc'}}>toko chat</h1>

                       <div className='row mt-5 mx-auto' style={{maxWidth: '1600px', justifyContent: 'space-between'}}>
                       <div className='col-lg-6 col-md-12' style={{zIndex: 2, maxWidth: '700px' , color: '#db6afa',}}>
                       <h6 style ={{fontSize:'18px'}}>دردشة مجانية مع اصدقائك من مختلف العالم</h6>
                        <h6 style ={{fontSize:'18px'}}>تعرف الان من خلال موقعنا علي كل من يجمع بين اهتمامك و مجالك من خلالنا </h6>
                        <h6 style ={{fontSize:'18px'}}>نحن توكو شات ارتقي الي الافضل</h6>
                       </div>
                        <div className='col-lg-6 col-md-12' style={{zIndex: 2 , maxWidth:'700px',  color: 'rgb(0 197 147)'}}>
                        <h6 style ={{fontSize:'18px'}}>مع الشات الكتابي و الصوت و الفيديو اسماء مميزة و محمية </h6>
                        <h6 style ={{fontSize:'18px'}}>نحن نتطلع لارائكم نحو الافضل .</h6>
                        </div>
                       </div>
                          <div style={{marginTop: '-150px'}}>
                            
                          <img className='mt-5' src= {logo} width ='600' style={{opacity: '0.5'}}/>
                      <div className='row mx-auto' style={{marginTop: '-350px' , maxWidth: '1200px'}}>
                        <div className='col-lg-4 col-md-12'>
                          <div className='card mx-auto shadow' style ={{width: '350px' , border: '5px solid #db6afa', color: '#db6afa', borderRadius: '25px'}}>
                            <div className='card-body text-center'>
                              <img className='mt-3 icon-call' src = {img2}/>

                              <h6 className='mt-4'>تواصل معنا عبر الايميل</h6>

                              <p className='text-primary mt-4' style ={{letterSpacing: '1.5px'}}>advanziaroom@gmail.com</p>
                               
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4 col-md-12' style={{marginTop: '100px'}}>
                          <div className='card mx-auto shadow' style ={{width: '350px' , border: '5px solid rgb(43 199 252)', color: ' rgb(43 199 252)', borderRadius: '25px'}}>
                            <div className='card-body text-center'>
                              <img className='mt-3 icon-call' src = {img4}/>

                              <h6 className='mt-4'>تواصل معنا عبر الفيسبوك</h6>

                             <div className='my-4 pt-3'>
                             <a href='https://m.facebook.com/ertqa2x' className='text-primary  face-link ' style ={{letterSpacing: '1.5px', direction: 'ltr'}}>
                               اضغط هنا
                              </a >
                             </div>
                               
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4 col-md-12'>
                          <div className='card mx-auto shadow' style ={{width: '350px' , border: '5px solid rgb(43 217 173)', color: 'rgb(43 217 173)', borderRadius: '25px'}}>
                            <div className='card-body text-center'>
                              <img className='mt-3 icon-call' src = {img3}/>

                              <h6 className='mt-4'>تواصل معنا عبر الهاتف</h6>

                              <p className='text-primary mt-4 ' style ={{letterSpacing: '1.5px', direction: 'ltr'}}>+49 15771206561</p>
                               
                            </div>
                          </div>
                        </div>
                         
                      
                          </div>

                          
                      </div>
                    </div>
                </div>
             
            </div>
        )
    }
}

export default Contact